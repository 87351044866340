import type { ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Skeleton } from '@repo/domain-kit/payment-links';
import type { Document } from 'qonto/react/receivable-invoices/types';
import { RelatedDocument } from 'qonto/react/receivable-invoices/components/related-document';
import styles from './styles.strict-module.css';

interface RelatedDocumentsSectionProps {
  resource: Document;
  isLoading: boolean;
}

export function RelatedDocumentsSection({
  resource,
  isLoading,
}: RelatedDocumentsSectionProps): ReactNode {
  const { formatMessage } = useIntl();

  const hasDocument = (): boolean => {
    return Boolean(resource.id);
  };

  return (
    <div data-test-related-documents-section>
      <p className={cx(styles.label, 'body-1')} data-test-related-documents-section-label>
        {formatMessage({ id: 'payment-links.sidebar.related-document' })}
      </p>
      {isLoading ? (
        <Skeleton id="related-documents-skeleton" itemsCount={1} />
      ) : (
        hasDocument() && <RelatedDocument document={resource} />
      )}
    </div>
  );
}
