import type { ReactNode } from 'react';
import {
  type PaymentLink,
  PaymentLinkResourceType,
  PaymentLinkStatus,
} from '@repo/domain-kit/payment-links';
import {
  DetailsList,
  PaymentMethodIcon,
  type DetailsItem,
  PaymentMethods,
} from '@repo/domain-kit/shared';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface DetailsProps {
  paymentLink: PaymentLink;
}

export function Details({ paymentLink }: DetailsProps): ReactNode {
  const { formatMessage, formatDate } = useIntl();

  const isStandalone = paymentLink.paymentInitiator.resourceType === PaymentLinkResourceType.Basket;
  const isPaid = paymentLink.status === PaymentLinkStatus.Paid;

  const getPaymentMethodAriaLabel = (method: PaymentMethods): string => {
    switch (method) {
      case PaymentMethods.ApplePay:
        return formatMessage({
          id: 'payment-links.sidebar.payment-methods.aria-label.apple-pay',
        });
      case PaymentMethods.BankTransfer:
        return formatMessage({
          id: 'payment-links.sidebar.payment-methods.aria-label.bank-transfer',
        });
      case PaymentMethods.CreditCard:
        return formatMessage({
          id: 'payment-links.sidebar.payment-methods.aria-label.credit-card',
        });
      case PaymentMethods.Ideal:
        return formatMessage({
          id: 'payment-links.sidebar.payment-methods.aria-label.ideal',
        });
      case PaymentMethods.Paypal:
        return formatMessage({
          id: 'payment-links.sidebar.payment-methods.aria-label.paypal',
        });
    }
  };

  const getPaymentMethodSection = (): DetailsItem | null => {
    const isOpen = paymentLink.status === PaymentLinkStatus.Open;
    const isCanceled = paymentLink.status === PaymentLinkStatus.Canceled;
    const isExpired = paymentLink.status === PaymentLinkStatus.Expired;

    if (isOpen) {
      return {
        label: formatMessage({
          id: 'payment-links.sidebar.accepted-methods',
        }),
        text: (
          <div className={styles['payment-method-icons-container']}>
            {paymentLink.potentialPaymentMethods.map(method => (
              <PaymentMethodIcon
                aria-label={getPaymentMethodAriaLabel(method)}
                className={styles['payment-method-icon']}
                key={method}
                method={method}
              />
            ))}
          </div>
        ),
      };
    } else if (isPaid && !isStandalone) {
      return {
        label: formatMessage({
          id: 'payment-links.sidebar.paid-with',
        }),
        text: (
          <PaymentMethodIcon
            aria-label={getPaymentMethodAriaLabel(paymentLink.latestPaymentMethod)}
            className={cx(styles['payment-method-icon'], styles['paid-payment-method-icon'])}
            method={paymentLink.latestPaymentMethod}
          />
        ),
      };
    } else if (isCanceled || isExpired) {
      return {
        label: formatMessage({
          id: 'payment-links.sidebar.accepted-methods',
        }),
        text: formatMessage({ id: 'payment-link.create.step-1.item.create.name.empty' }),
      };
    }

    return null;
  };

  const getDetailsItems = (): DetailsItem[] => {
    const items = [
      {
        label: formatMessage({
          id: 'payment-links.sidebar.link-type',
        }),
        text: paymentLink.settings.reusable
          ? formatMessage({ id: 'payment-links.sidebar.reusable' })
          : formatMessage({ id: 'payment-links.sidebar.one-off' }),
      },
      {
        label: formatMessage({
          id: 'payment-links.sidebar.expiration-date',
        }),
        text: formatDate(paymentLink.expirationDate, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        }),
      },
    ];

    const paymentMethodSection = getPaymentMethodSection();

    if (paymentMethodSection) {
      return [paymentMethodSection, ...items];
    }

    return items;
  };

  return (
    <DetailsList
      className={isPaid && !isStandalone ? styles['paid-details-list'] : styles['details-list']}
      data-test-details-list
      items={getDetailsItems()}
    />
  );
}
