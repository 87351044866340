import {
  useInfiniteQuery,
  type InfiniteData,
  type UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { paymentLinkV2Namespace } from 'qonto/constants/hosts';
import ENV from 'qonto/config/environment';
import type { Payment } from '../api/models';
import { useFetchApi, type FetchFunction } from './use-fetch-api';

const isTesting = (ENV as { environment: string }).environment === 'test';

export const DEFAULT_ITEMS_PER_PAGE = 50;

interface FetchPaymentArgs {
  page?: number;
  perPage?: number;
  sortBy?: string;
  paymentLinkId: string;
}

export interface PaymentsResponse {
  data: Payment[];
  meta: {
    total: number;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    current_page: number;
    per_page: number;
  };
}

type UseFetchInfinitePaymentLinkItemsResult = UseInfiniteQueryResult<
  InfiniteData<PaymentsResponse>
>;

export async function getPayments(
  page: number,
  perPage: number,
  sortBy: string,
  paymentLinkId: string,
  fetchApi: FetchFunction
): Promise<PaymentsResponse> {
  const params = new URLSearchParams({
    'page[number]': page.toString(),
    'page[size]': perPage.toString(),
    'filter[status]': 'paid',
    sort: sortBy,
  });

  const response = await fetchApi(
    `${paymentLinkV2Namespace}/payment_links/${paymentLinkId}/payments?${params.toString()}`
  );

  if (!response.ok) {
    throw new Error('Failed to fetch payments');
  }

  return (await response.json()) as PaymentsResponse;
}

export function useFetchInfinitePayments({
  perPage = DEFAULT_ITEMS_PER_PAGE,
  sortBy = '-creation_date',
  paymentLinkId,
}: Omit<FetchPaymentArgs, 'page'>): UseFetchInfinitePaymentLinkItemsResult {
  const fetchApi = useFetchApi();

  return useInfiniteQuery({
    queryKey: ['infinite-payments', sortBy, perPage],
    queryFn: ({ pageParam }) => getPayments(pageParam, perPage, sortBy, paymentLinkId, fetchApi),
    getNextPageParam: lastPage => lastPage.meta.next_page,
    initialPageParam: 1,
    retry: isTesting ? false : 3,
  });
}
