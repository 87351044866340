import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import {
  PaymentLinkResourceType,
  PaymentLinkStatus,
  type PaymentLink,
} from '@repo/domain-kit/payment-links';
import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';
import { receivableInvoicesURL } from 'qonto/constants/hosts';
import styles from './styles.strict-module.css';

interface LinkSectionProps {
  paymentLink: PaymentLink;
}

export function LinkSection({ paymentLink }: LinkSectionProps): ReactNode {
  const { formatMessage } = useIntl();

  const isDisabled = paymentLink.status !== PaymentLinkStatus.Open;

  const getShareableLink = (): string => {
    const invoiceLink = `${receivableInvoicesURL}/invoices/${paymentLink.paymentInitiator.resourceId}`;
    const standaloneLink = `${receivableInvoicesURL}/payment-links/${paymentLink.id}?resource_id=${paymentLink.paymentInitiator.resourceId}`;

    const isStandalone =
      paymentLink.paymentInitiator.resourceType === PaymentLinkResourceType.Basket;

    return isStandalone ? standaloneLink : invoiceLink;
  };

  return (
    <div data-test-link-section>
      <dt className={cx(styles.label, 'body-1')}>
        {formatMessage({ id: 'payment-links.sidebar.link' })}
      </dt>
      <dd className={styles.value}>
        <a
          className={cx(styles.link, isDisabled && styles.disabled)}
          data-test-link
          href={getShareableLink()}
          rel="noopener noreferrer"
          target="_blank"
        >
          {getShareableLink()}
        </a>
        {!isDisabled && (
          <CopyToClipboard
            aria-label={formatMessage({ id: 'payment-links.sidebar.copy-payment-link.aria-label' })}
            className={styles['copy-btn']}
            clipboardText={getShareableLink()}
            data-test-link-copy
            toastMessage={formatMessage({ id: 'payment-links.sidebar.copy-payment-link.toast' })}
            trackEvent="payment-links_link_copied"
            trackParams={{ origin: 'payment-links' }}
          />
        )}
      </dd>
    </div>
  );
}
