import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useToast } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';

interface CreateCertificateParams {
  accountId: string;
  locale: string;
  withBalance: boolean;
}

interface CreateCertificateResult {
  success: boolean;
}

export const useCreatePdfCertificate = (): UseMutationResult<
  CreateCertificateResult,
  Error,
  CreateCertificateParams
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const sentry = useEmberService('sentry');
  const segment = useEmberService('segment');

  return useMutation({
    mutationFn: async ({ accountId, locale, withBalance }: CreateCertificateParams) => {
      const response = await fetchApi(`v5/bank_accounts/${accountId}/account_certificate`, {
        method: 'POST',
        body: JSON.stringify({ locale, with_balance: withBalance }),
      });

      if (!response.ok) {
        throw new Error(response.statusText || 'Failed to create certificate');
      }

      return { success: true };
    },
    onError: error => {
      sentry.captureException(error);
      showToast({
        text: formatMessage({ id: 'requests.mileage.steps.journey.errors.something-wrong' }),
        type: 'error',
      });
      segment.track('account-certification_generation-error');
    },
  });
};
